<!--
// nuxt-ui/components/pages/region-home/GridNewsHero.vue
-->
<script setup lang="ts">
import type { DateTimeFormatOptions } from "@intlify/core-base";
import type { Post } from "~/src/post/domain/models/Post";
import postUiService from "~/src/post/infrastructure/ui-services/postUiService";
import { isFutureDate, isPastDate, setAliasUrl } from "~/src/Shared/utils";
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";
import BaseNuxtLink from "~/nuxt-ui/components/base/baseNuxtLink.vue";
import ByExternalMedia from "~/nuxt-ui/components/Shared/news/ByExternalMedia.vue";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import { useTwitch } from "~/nuxt-ui/Shared/composables/twitch";

type Props = {
  seeMoreButton?: boolean;
};

const route = useRoute();
const props = defineProps<Props>();
const { locale } = useI18n();
const localePath = useLocalePath();
const { getFeaturedPosts, isExternal, localeHasNews } = postUiService();
const { isRegionHome, isSpain, isAmericas } = useRegions();
const { isKings, isQueens } = useWebContext();
const { getTwitchLiveInfo } = useTwitch();

const twitchIsLive = ref<boolean>(false);
const isMobile = ref<boolean | undefined>();
// const isBetweenSundayAndMonday = ref<boolean | undefined>(false);
// const isBetweenSaturdayAndSunday = ref<boolean | undefined>(false);
const isPlayoffsWeekSpain = ref<boolean | undefined>(false);
const isPlayoffsWeekAmericas = ref<boolean | undefined>(false);

const showNews = localeHasNews(locale.value);
const activeNew = ref(0);
const posts = ref<Post[] | undefined>([]);

const gridKey = ref(0);

const dateOptions: DateTimeFormatOptions = {
  month: "long",
  day: "numeric",
  year: "numeric",
};

posts.value = await getFeaturedPosts(locale.value);
gridKey.value++;

const [firstPost, secondPost, thirdPost] = !!posts.value ? posts.value : [];

const updateTwitchStatus = async (): Promise<void> => {
  const twitchInfo = await getTwitchLiveInfo();
  if (twitchInfo) {
    twitchIsLive.value = twitchInfo.live;
  }
};

onMounted(async () => {
  await nextTick();
  if (!posts.value || (!!posts.value && !posts.value?.length)) {
    posts.value = await getFeaturedPosts(locale.value);
    gridKey.value++;
  }

  isMobile.value = window.innerWidth < 1025;
  window.addEventListener("resize", () => {
    isMobile.value = window.innerWidth < 1025;
  });

  await updateTwitchStatus();

  // isBetweenSundayAndMonday.value =
  //   isKings && isPastDate("2024-12-08T10:00:00Z") && isFutureDate("2024-12-09T08:00:00Z");
  //
  // isBetweenSaturdayAndSunday.value =
  //   isQueens && isPastDate("2024-12-07T10:00:00Z") && isFutureDate("2024-12-08T08:00:00Z");

  isPlayoffsWeekSpain.value = isPastDate("2024-12-12T10:00:00Z") && isFutureDate("2024-12-15T23:59:00Z");
  isPlayoffsWeekAmericas.value = isPastDate("2024-12-14T10:00:00Z") && isFutureDate("2024-12-16T10:00:00Z");
});

const showSpainEmbedTwitch = computed(() => {
  return twitchIsLive.value && isMobile.value && isSpain && !!isPlayoffsWeekSpain.value;
});

const showAmericasEmbedTwitch = computed(() => {
  return twitchIsLive.value && isMobile.value && isAmericas && !!isPlayoffsWeekAmericas.value;
});
</script>

<template>
  <section v-if="posts && posts.length && showNews" :key="gridKey">
    <div v-if="posts" class="posts-grid-section kql-w-container">
      <div v-if="showSpainEmbedTwitch || showAmericasEmbedTwitch" class="relative h-full w-full posts">
        <iframe
          v-if="isAmericas"
          src="https://player.twitch.tv/?channel=kingsleagueamericas&parent=kingsleague.pro&parent=queensleague.pro&muted=true"
          allowfullscreen
          height="242"
          width="100%"
        ></iframe>
        <iframe
          v-else-if="isSpain"
          src="https://player.twitch.tv/?channel=kingsleague&parent=kingsleague.pro&parent=queensleague.pro&muted=true"
          allowfullscreen
          height="242"
          width="100%"
        ></iframe>
      </div>
      <nuxt-link
        v-for="(post, i) in showSpainEmbedTwitch || showAmericasEmbedTwitch
          ? [firstPost, secondPost].filter(Boolean)
          : [firstPost, secondPost, thirdPost].filter(Boolean)"
        :key="post.id"
        :to="
          isExternal(post.categories)
            ? post.acf.external_url
            : localePath({
                name: 'region-news-post',
                params: { region: route.params.region, postSlug: post.slug },
              })
        "
        :target="post.target"
        :class="[{ 'is-active': activeNew === i }, 'posts']"
        @mouseover="activeNew = i"
        :no-prefetch="!!isRegionHome"
      >
        <div class="relative h-full w-full">
          <nuxtImg
            :src="post.seoHeadJSON.og_image ? setAliasUrl(post.seoHeadJSON.og_image[0].url) : ''"
            :alt="post.title"
            width="1672"
            height="940"
            sizes="430px md:836px"
            quality="70"
            class="post-image"
          ></nuxtImg>
          <div class="text-gradient"></div>
          <div class="container-text">
            <by-external-media
              v-if="isExternal(post.categories) && !!post.acf.external_logo_url"
              :logo_url="post.acf.external_logo_url"
            />
            <p class="posts-date">
              {{ new Date(post.dateGmt).toLocaleDateString(locale, dateOptions) }}
            </p>

            <p class="posts-title" v-html="post.title"></p>
          </div>
        </div>
      </nuxt-link>
    </div>
  </section>
  <div
    v-if="seeMoreButton && posts && posts.length > 1 && showNews"
    class="py-6 flex justify-center items-center"
  >
    <base-nuxt-link :to="localePath('region-News')" text-size="text-xs" primary :no-prefetch="!!isRegionHome">
      {{ $t("news.post.viewAllNews") }}
    </base-nuxt-link>
  </div>
</template>

<style scoped>
.posts-grid-section {
  @apply grid grid-cols-2 md:flex md:justify-center md:items-center md:h-[470px] overflow-hidden;
}

.posts {
  @apply bg-center bg-no-repeat bg-cover flex flex-col justify-end overflow-hidden md:w-1/5 md:h-full md:first:h-full h-[242px] first:col-span-2 md:transition-[width] md:ease-in-out md:duration-500;
}

.posts .post-image {
  @apply absolute top-0 left-0 bottom-0 right-0 w-full h-full object-cover md:transition-transform md:ease-in-out md:duration-500;
}

.posts .posts-date {
  @apply text-[10px] font-normal uppercase mb-2 lg:text-xs;
}

.posts .posts-title {
  @apply text-sm font-normal leading-[17.5px] text-gray-200 md:text-lg md:leading-snug md:font-medium md:text-white;
}

.posts .container-text {
  @apply px-3 pt-3 pb-7 absolute bottom-0 md:pb-14 md:px-6 md:max-w-max md:duration-500 md:ease-in-out;
}

.posts .text-gradient {
  @apply h-full w-full absolute top-0 right-0 bottom-0 left-0;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 10%, rgba(0, 0, 0, 0) 70%);
}

.posts.is-active {
  @apply md:w-3/5;
}

.posts.is-active .container-text {
  @apply md:scale-150 md:origin-bottom-left md:max-w-xs lg:max-w-md md:pl-14 md:pr-0;
}

.posts.is-active .posts-date {
  @apply md:text-[10px];
}

.posts.is-active .posts-title {
  @apply md:font-semibold md:leading-[23px];
}
</style>
